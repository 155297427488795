<template>
	<div class="hx-order-seven">
		
				<div class="left" align="left">
					<img src="../../assets/ysmimg/ysmorderoneimg.png" width="90px" height="110px" style="vertical-align: middle;" >
					<div>
						<h3>上传面相照片</h3>
						<div align="left" style="margin-bottom: 0.625rem;">请在此处上传头部示例照片。</div>
						<div align="left" >您可以在<a href="./hxmx.pdf" target="_blank"  style="color: #0D8EA4;font-weight: bold;text-decoration: underline;">此处</a>找到有关图片的示例。</div>
						<div>
							<el-form :model="ruleForm"  ref="ruleForm">
								<el-form-item prop="chengxiangDate" label="成像日期">
								  <el-date-picker :picker-options="filerData.pickerOptions"  type="date" placeholder="成像日期" v-model="ruleForm.chengxiangDate" style="width: 100%;" value-format="yyyy-MM-dd" format="yyyy/MM/dd"></el-date-picker>		     
								</el-form-item>
							</el-form>
							
						</div>
						<div align="left" style="margin-bottom: 0.625rem;"><span style="font-weight: bold;">注意</span>：图像需近30天内</div>
						<div align="left" style="margin-bottom: 0.625rem;"><span style="font-weight: bold;">有效格式</span>：.jpg、.jpeg、png、.tif、.tiff</div>
						<div align="left" style="margin-bottom: 0.625rem;"><span style="font-weight: bold;">最小文件大小</span>：500KB</div>
						<div align="left" ><span style="font-weight: bold;">最大文件大小</span>：11MB</div>
					</div>
				</div>
				
			
			
				<div class="right">
					<!-- 请上传正面无笑容 -->
					<div class="shanghe"  style="display: inline-block;margin-right: 0.625rem;">
						<div style="position: relative;left: -4.8rem;font-size: 0.875rem;">请上传正面无笑容</div>
						<el-upload
						  class="upload-demo"
						  drag
						  action=''
						  ref="upload"
						  :http-request="changeFile"
						  multiple
						 
						  :before-upload="beforeAvatarUpload"
						  style="margin-bottom: 30px;"
						  >
						   <img v-show="zwVisiable" class="zw" :src="zwPicUrl" style="width: 100%; height: 100%;" />
						    <div v-show="zhengwuVisiable" style="width: 100%; min-height: 30px;line-height: 30px;position: absolute;top: 0px;background-color: #0D8EA4;color: white;word-break: break-all;" >{{zhengwuMess}}</div>
						   <img style="margin-bottom: 10px;margin-top: 20px;" src="../../assets/mianxiang/zhengwuxiao.png" width="100" height="120" />
						  <div class="el-upload__text">将文件拖到此处或<em>点击上传</em><br/>如修改请重新点击上传</div>
						  <div class="el-upload__tip" slot="tip"></div>
						</el-upload>
						
					</div>
					<!-- 请上传斜45度无笑容 -->
					<div class="xiahe"  style="display: inline-block;">
					<div style="margin-top: 0.3125rem;font-size: 0.875rem;position: relative;left: -4.25rem;">请上传斜45度无笑容</div>
					<el-upload
					  class="upload-demo1"
					  drag
					  action=''
					  multiple
					  :http-request="changeFile1"
					  >
					   <img v-show="xwVisiable" class="xw" :src="xwPicUrl" style="width: 100%; height: 100%;" />
					   <div v-show="xiewuVisiable" style="width: 100%; min-height: 30px;line-height: 30px;position: absolute;top: 0px;background-color: #0D8EA4;color: white;word-break: break-all;" >{{xiewuMess}}</div>
					  <img style="margin-bottom: 10px;margin-top: 20px;" src="../../assets/mianxiang/xie.png" width="100" height="120" />
					  <div class="el-upload__text">将文件拖到此处或<em>点击上传</em><br/>如修改请重新点击上传</div>
					  <div class="el-upload__tip" slot="tip"></div>
					</el-upload>
					</div>
					<br />
					<!-- 侧面无笑容 -->
					<div class="yaohe" style="display: inline-block;margin-right: 0.625rem;">
					<div style="margin-top: 0.3125rem;font-size: 0.875rem;position: relative;left: -5rem;">请上传侧面无笑容</div>
					<el-upload
					  class="upload-demo1"
					  drag
					  action=''
					  multiple
					  :http-request="changeFile2"
					  >
					    <img v-show="cwVisiable" class="cw" :src="cwPicUrl" style="width: 100%; height: 100%;" />
					   <div v-show="cewuVisiable" style="width: 100%; min-height: 30px;line-height: 30px;position: absolute;top: 0px;background-color: #0D8EA4;color: white;word-break: break-all;" >{{cewuMess}}</div>
					  <img style="margin-bottom: 10px;margin-top: 20px;" src="../../assets/mianxiang/ce.png" width="100" height="120" />
					  <div class="el-upload__text">将文件拖到此处或<em>点击上传</em><br/>如修改请重新点击上传</div>
					  <div class="el-upload__tip" slot="tip"></div>
					</el-upload>
					</div>
					<!-- 正面微笑-->
					<div class="yaohe"  style="display: inline-block;">
					<div style="margin-top: 0.3125rem;font-size: 0.875rem;position: relative;left: -5rem;">请上传正面微笑</div>
					<el-upload
					  class="upload-demo1"
					  drag
					  action=''
					  multiple
					  :http-request="changeFile3"
					  style="margin-bottom: 30px;"
					  >
					   <img v-show="zxVisiable" class="zx" :src="zxPicUrl" style="width: 100%; height: 100%;" />
					   <div v-show="zhengxiaoVisiable" style="width: 100%; min-height: 30px;line-height: 30px;position: absolute;top: 0px;background-color: #0D8EA4;color: white;word-break: break-all;" >{{zhengxiaoMess}}</div>
					  <img style="margin-bottom: 10px;margin-top: 20px;" src="../../assets/mianxiang/zhengxiao.png" width="100" height="120" />
					  <div class="el-upload__text">将文件拖到此处或<em>点击上传</em><br/>如修改请重新点击上传</div>
					  <div class="el-upload__tip" slot="tip"></div>
					</el-upload>
					</div>
					<div align="right">
						<el-button @click="back" class="back"  type="button">返回</el-button>
						<el-button @click="nextStepKN" class="nextStep" type="button">下一步</el-button>
					</div>
				</div>
			
	</div>
</template>

<script>
	export default{
		data:function(){
			return{
				zhengwuVisiable:false,
				xiewuVisiable:false,
				cewuVisiable:false,
				zhengxiaoVisiable:false,
				zhengwuMess:'',
				cewuMess:'',
				xiewuMess:'',
				zhengxiaoMess:'',
				zxUpload:false,
				xwUpload:false,
				cwUpload:false,
				zxUpload:false,
				
				
				filerData: {
				  pickerOptions: {
				    disabledDate(time) {
				      return time.getTime() > Date.now() - 8.64e6
				    }
				  },
				},
				zwVisiable:false,
				xwVisiable:false,
				cwVisiable:false,
				zxVisiable:false,
				zwPicUrl:'',
				xwPicUrl:'',
				cwPicUrl:'',
				zxPicUrl:'',
				ruleForm:{
					chengxiangDate:'',
					oid:'',
					
				}
			}
		},
		methods:{
			back(){
				this.$store.commit("setHxStepIndex",7);
				this.$router.push("/hxmain/hxorder/hxordersix");
			},
			beforeAvatarUpload(file) {
			    let types = ['image/jpeg', 'image/jpg', 'image/png'];
			    const isImage = types.includes(file.type);
			    if (!isImage) {
			        this.$message.error('上传图片只能是 .jpg、.jpeg、.dcm(DICOM)、.bmp、.tif、.tiff 格式!');
			      }else{
			        //this.uploadToken.key=`upload_pic_${Date.parse(new Date())}${file.name}`
			      }
				  const isLtSize = file.size / 1024 / 1024 < 5;
				   if (!isLtSize) {
				            this.$message.error('上传图片大小不能超过 1MB!');
				       }
			    },
				
				//上传正面无笑容
			changeFile(file){
				if(this.xwUpload==true||this.cwUpload==true||this.zxUpload==true){
					this.$alert("已有文件上传中,请稍候!");
					return;
				}
				this.zwUpload=true;
				      let formData = new FormData()
				      formData.append('file', file.file)// 传文件
				      formData.append('oid', this.ruleForm.oid)
					  formData.append('type', 'mxZheng')
				      let self = this
				   let config = { headers: { "Content-Type": "multipart/form-data" }};
				   this.zhengwuVisiable=false;
				   /* this.$axios.post('client/order/uploadResourceFile', formData,config)
				       .then(res=>{
				   		
						if(res.data.code==200){
							this.$message.success("上传成功");
							this.zwVisiable=true;
							this.zwPicUrl=res.data.data.picUrl;
							
						}
				   	}).catch(err=>{
				   		this.$alert("系统出现异常,请联系管理员!");
				   	}); */
					this.$axios({
					                    method:'post',
					                    headers: {
					                        "Content-Type": "multipart/form-data"
					                    },
					                    url:'client/order/uploadResourceFile',//自定义上传url
					                    data:formData,
					                    onUploadProgress: progressEvent => {
											
											 if (progressEvent.lengthComputable) {
											                        //属性lengthComputable主要表明总共需要完成的工作量和已经完成的工作是否可以被测量
											                        //如果lengthComputable为false，就获取不到progressEvent.total和progressEvent.loaded
											                     let percent=(progressEvent.loaded / progressEvent.total * 100) | 0
											                     file.onProgress({percent:percent});//调用uploader的进度回调
																 if(percent==100){
																 		this.zhengwuMess="文件已上传至服务器,资源解析中......";
																 		this.zhengwuVisiable=true;
																 }
											                    }
					                         
					                    }
					                }).then((res)=>{
					                    if(res.data.code==200){
											this.zwUpload=false;
											 let pros=document.getElementsByClassName("el-progress el-progress--line");
											for(let i=0;i<pros.length;i++){
												pros[i].style.display="none";
											} 
											let fName=res.data.data.picUrl.substring(27,res.data.data.picUrl.length);
											 this.zhengwuMess="资源上传解析完毕："+res.data.data.fileName;
											 var houZhui=res.data.data.picUrl.substring(res.data.data.picUrl.lastIndexOf('.')+1);
											 console.log(houZhui);
											 if(houZhui=='jpg'||houZhui=='JPG'||houZhui=='PNG'||houZhui=='png'||houZhui=='jpeg'||houZhui=='JPEG'){
												 this.zwVisiable=true;
												 this.zwPicUrl=res.data.data.picUrl;
											 }else{
												  this.zwPicUrl="";
												  this.zwVisiable=false;
											 }
					                    
											this.$message({
												message:"上传成功",
												type:"success",
												duration:900
											});
											
					                    }else{
					                        this.$message.error('上传失败');
					                    }
					                }).catch((err)=>{
					                    this.$message.error(err);
					                })
			},
			//上传斜45度无笑容
			changeFile1(file){
				if(this.zwUpload==true||this.cwUpload==true||this.zxUpload==true){
					this.$alert("已有文件上传中,请稍候!");
					return;
				}
				this.xwUpload=true;
				      let formData = new FormData()
				      formData.append('file', file.file)// 传文件
				      formData.append('oid', this.ruleForm.oid)
					  formData.append('type', 'mxXie')
				      let self = this
				   let config = { headers: { "Content-Type": "multipart/form-data" }};
				   this.xiewuVisiable=false;
				  /* this.$axios.post('client/order/uploadResourceFile', formData,config)
				       .then(res=>{
						if(res.data.code==200){
							this.$message.success("上传成功");
							this.xwVisiable=true;
							this.xwPicUrl=res.data.data.picUrl;
						}
				   	}).catch(err=>{
				   		this.$alert("系统出现异常,请联系管理员!");
				   	}); */
					this.$axios({
					                    method:'post',
					                    headers: {
					                        "Content-Type": "multipart/form-data"
					                    },
					                    url:'client/order/uploadResourceFile',//自定义上传url
					                    data:formData,
					                    onUploadProgress: progressEvent => {
											
											 if (progressEvent.lengthComputable) {
											                        //属性lengthComputable主要表明总共需要完成的工作量和已经完成的工作是否可以被测量
											                        //如果lengthComputable为false，就获取不到progressEvent.total和progressEvent.loaded
											                     let percent=(progressEvent.loaded / progressEvent.total * 100) | 0
											                     file.onProgress({percent:percent});//调用uploader的进度回调
																 if(percent==100){
																 		this.xiewuMess="文件已上传至服务器,资源解析中......";
																 		this.xiewuVisiable=true;
																 }
											                    }
					                         
					                    }
					                }).then((res)=>{
					                    if(res.data.code==200){
											this.xwUpload=false;
											 let pros=document.getElementsByClassName("el-progress el-progress--line");
											for(let i=0;i<pros.length;i++){
												pros[i].style.display="none";
											} 
											let fName=res.data.data.picUrl.substring(27,res.data.data.picUrl.length);
											 this.xiewuMess="资源上传解析完毕："+res.data.data.fileName;
											 var houZhui=res.data.data.picUrl.substring(res.data.data.picUrl.lastIndexOf('.')+1);
											 console.log(houZhui);
											 if(houZhui=='jpg'||houZhui=='JPG'||houZhui=='PNG'||houZhui=='png'||houZhui=='jpeg'||houZhui=='JPEG'){
												 this.xwVisiable=true;
												 this.xwPicUrl=res.data.data.picUrl;
											 }else{
												  this.xwPicUrl="";
												  this.xwVisiable=false;
											 }
					                    
											this.$message({
												message:"上传成功",
												type:"success",
												duration:900
											});
											
					                    }else{
					                        this.$message.error('上传失败');
					                    }
					                }).catch((err)=>{
					                    this.$message.error(err);
					                })
			},
			//上传侧面无笑容
			changeFile2(file){
				
				if(this.xwUpload==true||this.zwUpload==true||this.zxUpload==true){
					this.$alert("已有文件上传中,请稍候!");
					return;
				}
				this.cwUpload=true;
				      let formData = new FormData()
				      formData.append('file', file.file)// 传文件
				      formData.append('oid', this.ruleForm.oid)
					  formData.append('type', 'mxCe')
				      let self = this
				   let config = { headers: { "Content-Type": "multipart/form-data" }};
				   this.cewuVisiable=false;
				   /* this.$axios.post('client/order/uploadResourceFile', formData,config)
				       .then(res=>{
						if(res.data.code==200){
							this.$message.success("上传成功");
							this.cwVisiable=true;
							this.cwPicUrl=res.data.data.picUrl;
						}
				   		//document.getElementById("i").setAttribute("src",res.data.data.picUrl);
				   	}).catch(err=>{
				   		this.$alert("系统出现异常,请联系管理员!");
				   	}); */
					this.$axios({
					                    method:'post',
					                    headers: {
					                        "Content-Type": "multipart/form-data"
					                    },
					                    url:'client/order/uploadResourceFile',//自定义上传url
					                    data:formData,
					                    onUploadProgress: progressEvent => {
											
											 if (progressEvent.lengthComputable) {
											                        //属性lengthComputable主要表明总共需要完成的工作量和已经完成的工作是否可以被测量
											                        //如果lengthComputable为false，就获取不到progressEvent.total和progressEvent.loaded
											                     let percent=(progressEvent.loaded / progressEvent.total * 100) | 0
											                     file.onProgress({percent:percent});//调用uploader的进度回调
																 if(percent==100){
																 		this.cewuMess="文件已上传至服务器,资源解析中......";
																 		this.cewuVisiable=true;
																 }
											                    }
					                         
					                    }
					                }).then((res)=>{
					                    if(res.data.code==200){
											this.cwUpload=false;
											 let pros=document.getElementsByClassName("el-progress el-progress--line");
											for(let i=0;i<pros.length;i++){
												pros[i].style.display="none";
											} 
											let fName=res.data.data.picUrl.substring(27,res.data.data.picUrl.length);
											 this.cewuMess="资源上传解析完毕："+res.data.data.fileName;
											 var houZhui=res.data.data.picUrl.substring(res.data.data.picUrl.lastIndexOf('.')+1);
											 console.log(houZhui);
											 if(houZhui=='jpg'||houZhui=='JPG'||houZhui=='PNG'||houZhui=='png'||houZhui=='jpeg'||houZhui=='JPEG'){
												 this.cwVisiable=true;
												 this.cwPicUrl=res.data.data.picUrl;
											 }else{
												  this.cwPicUrl="";
												  this.cwVisiable=false;
											 }
					                    
											this.$message({
												message:"上传成功",
												type:"success",
												duration:900
											});
											
					                    }else{
					                        this.$message.error('上传失败');
					                    }
					                }).catch((err)=>{
					                    this.$message.error(err);
					                })
			},
			//正面微笑
			changeFile3(file){
				if(this.xwUpload==true||this.cwUpload==true||this.zwUpload==true){
					this.$alert("已有文件上传中,请稍候!");
					return;
				}
				this.zxUpload=true;
				      let formData = new FormData()
				      formData.append('file', file.file)// 传文件
				      formData.append('oid', this.ruleForm.oid)
					  formData.append('type', 'mxXiao')
				      let self = this
				   let config = { headers: { "Content-Type": "multipart/form-data" }};
				   this.zhengxiaoVisiable=false;
				  /* this.$axios.post('client/order/uploadResourceFile', formData,config)
				       .then(res=>{
						if(res.data.code==200){
							this.$message.success("上传成功");
							this.zxVisiable=true;
							this.zxPicUrl=res.data.data.picUrl;
						}
				   	}).catch(err=>{
				   		this.$alert("系统出现异常,请联系管理员!");
				   	}); */
					this.$axios({
					                    method:'post',
					                    headers: {
					                        "Content-Type": "multipart/form-data"
					                    },
					                    url:'client/order/uploadResourceFile',//自定义上传url
					                    data:formData,
					                    onUploadProgress: progressEvent => {
											
											 if (progressEvent.lengthComputable) {
											                        //属性lengthComputable主要表明总共需要完成的工作量和已经完成的工作是否可以被测量
											                        //如果lengthComputable为false，就获取不到progressEvent.total和progressEvent.loaded
											                     let percent=(progressEvent.loaded / progressEvent.total * 100) | 0
											                     file.onProgress({percent:percent});//调用uploader的进度回调
																 if(percent==100){
																 		this.zhengxiaoMess="文件已上传至服务器,资源解析中......";
																 		this.zhengxiaoVisiable=true;
																 }
											                    }
					                         
					                    }
					                }).then((res)=>{
					                    if(res.data.code==200){
											this.zxUpload=false;
											 let pros=document.getElementsByClassName("el-progress el-progress--line");
											for(let i=0;i<pros.length;i++){
												pros[i].style.display="none";
											} 
											let fName=res.data.data.picUrl.substring(27,res.data.data.picUrl.length);
											 this.zhengxiaoMess="资源上传解析完毕："+res.data.data.fileName;
											 var houZhui=res.data.data.picUrl.substring(res.data.data.picUrl.lastIndexOf('.')+1);
											 if(houZhui=='jpg'||houZhui=='JPG'||houZhui=='PNG'||houZhui=='png'||houZhui=='jpeg'||houZhui=='JPEG'){
												 this.zxVisiable=true;
												 this.zxPicUrl=res.data.data.picUrl;
											 }else{
												  this.zxPicUrl="";
												  this.zxVisiable=false;
											 }
					                    
											this.$message({
												message:"上传成功",
												type:"success",
												duration:900
											});
											
					                    }else{
					                        this.$message.error('上传失败');
					                    }
					                }).catch((err)=>{
					                    this.$message.error(err);
					                })
			},
			
			//下一步
			nextStepKN(){
				
				if(this.zwPicUrl!=''||this.xwPicUrl!=''||this.cwPicUrl!=''||this.zxPicUrl!=''){
					if(this.ruleForm.chengxiangDate==null||this.ruleForm.chengxiangDate==''){
						this.$alert("请填写成像日期","提示");
						return;
					}
				}
				
				if(this.ruleForm.chengxiangDate!=null&&this.ruleForm.chengxiangDate!=''){
					if(this.zwPicUrl!=''||this.xwPicUrl!=''||this.cwPicUrl!=''||this.zxPicUrl!=''){
						
					}else{
						this.$alert("请上传图片","提示");
						return;
					}
				}
				
				
				//上传日期
				 let formData1 = new FormData()
				formData1.append('date',this.ruleForm.chengxiangDate)// 传文件
				formData1.append('oid', this.ruleForm.oid)
				formData1.append('type',"mxDate")
			
				this.$axios.post("client/order/uploadResourceDate",formData1,{
					headers:{
						"Content-type":"application/json"
					}
				}).then(res=>{
					if(res.data.code==200){
						//this.$message.success("成功");
						//设置返回路径
						this.$store.commit("setBackLocation", "/hxmain/hxorder/hxorderseven");
						this.$store.commit('setHxStepIndex', 9);
						this.$router.push("/hxmain/hxorder/hxordernine");
					}
				}).catch(err=>{
					this.$message.error("添加失败");
				});
				
				this.$store.commit('setHxStepIndex', 9);
				
			},
			//返回
			goOrderFive(){
				this.$router.push("/hxmain/hxorder/hxordersix")
			}
		},
		mounted:function(){
			this.$store.commit('setHxStepIndex', 7);
			//设置默认地址
			sessionStorage.setItem("location","/hxmain/hxorder/hxorderseven")
			//获取当前添加的订单oid
			//获取oid
			// let oid=sessionStorage.getItem("oid");
			// if(oid==null){
			// 	this.$alert("请填写患者信息","提示");
			// 	this.$router.push("/addorder/orderone");
			// }else{
			// 	this.ruleForm.oid=oid;
			// }
			//设置返回路径
			this.$store.commit("setBackLocation","/hxmain/hxorder/hxorderseven");
		},
		created:function(){
			document.getElementsByClassName("saveb")[0].setAttribute("class","saveb show");
			document.getElementsByClassName("print")[0].setAttribute("class","print die");
				document.getElementsByClassName("fh")[0].setAttribute("class","fh show");
			/* document.getElementsByClassName("fun1")[0].setAttribute("class","fun1 sub-menue1 visible")
			 document.getElementsByClassName("fun")[0].setAttribute("class","fun sub-menue")
			document.getElementsByClassName("fun2")[0].setAttribute("class","fun2 sub-menue2");
			document.getElementsByClassName("fun3")[0].setAttribute("class","fun3 sub-menue3");	 */
				//如果是编辑订单要获取oid的值
				let oid=sessionStorage.getItem("oid");
			
				if(oid!="undefined"&&oid!=''&&oid!=null){
					this.ruleForm.oid=oid;
					this.$axios.get("/client/order/getOrderInfo",{
						params:{
							oid:this.ruleForm.oid
						}
					}).then(res=>{
						let mxDate=res.data.data.resource.mxDate;//口内日期
						let xiao=res.data.data.resource.mxXiao;//笑
						let xie=res.data.data.resource.mxXie;//斜
						let zheng=res.data.data.resource.mxZheng;//正
						let ce=res.data.data.resource.mxCe;//侧
						//日期回显
						if(mxDate!=null){
							this.ruleForm.chengxiangDate=mxDate;
						}
						//回显笑
						if(xiao!=null){
							let fName=xiao.substring(27,xiao.length);
							var houZhui=xiao.substring(xiao.lastIndexOf('.')+1);
							if(houZhui=='jpg'||houZhui=='JPG'||houZhui=='PNG'||houZhui=='png'||houZhui=='jpeg'||houZhui=='JPEG'){
																		this.zxVisiable=true;
																		this.zxPicUrl=xiao;
																		document.getElementsByClassName("zx")[0].setAttribute("src",xiao);
																		this.zhengxiaoVisiable=true;
																		this.zhengxiaoMess=fName;
							}else{
								this.zhengxiaoVisiable=true;
								this.zhengxiaoMess=fName;
									this.zxPicUrl="";
							}
						}
						//回显斜
						if(xie!=null){
							let fName=xie.substring(27,xie.length);
							var houZhui=xie.substring(xie.lastIndexOf('.')+1);
							if(houZhui=='jpg'||houZhui=='JPG'||houZhui=='PNG'||houZhui=='png'||houZhui=='jpeg'||houZhui=='JPEG'){
																		this.xwVisiable=true;
																		this.xwPicUrl=xie;
																		document.getElementsByClassName("xw")[0].setAttribute("src",xie);
																		this.xiewuVisiable=true;
																		this.xiewuMess=fName;
							}else{
								this.xiewuVisiable=true;
								this.xiewuMess=fName;
									this.xwPicUrl="";
							}
							
						}
						//回显正
						if(zheng!=null){
							let fName=zheng.substring(27,zheng.length);
							var houZhui=zheng.substring(zheng.lastIndexOf('.')+1);
							if(houZhui=='jpg'||houZhui=='JPG'||houZhui=='PNG'||houZhui=='png'||houZhui=='jpeg'||houZhui=='JPEG'){
																		this.zwVisiable=true;
																		this.zwPicUrl=zheng;
																		document.getElementsByClassName("zw")[0].setAttribute("src",zheng);
																		this.zhengwuVisiable=true;
																		this.zhengwuMess=fName;
							}else{
								this.zhengwuVisiable=true;
								this.zhengwuMess=fName;
									this.zwPicUrl="";
							}
							
						}
						//回显侧
						if(ce!=null){
							let fName=ce.substring(27,ce.length);
							var houZhui=ce.substring(ce.lastIndexOf('.')+1);
							if(houZhui=='jpg'||houZhui=='JPG'||houZhui=='PNG'||houZhui=='png'||houZhui=='jpeg'||houZhui=='JPEG'){
																		this.cwVisiable=true;
																		this.cwPicUrl=ce;
																		document.getElementsByClassName("cw")[0].setAttribute("src",ce);
																		this.cewuVisiable=true;
																		this.cewuMess=fName;
							}else{
								this.cewuVisiable=true;
								this.cewuMess=fName;
									this.cwPicUrl="";
							}
							
						}
						
					}).catch(err=>{
						this.$alert("系统出现异常,请联系管理员!");
					});
				}else{
					this.$alert('请填写患者信息', '提示', {
						confirmButtonText: '确定',
						callback: action => {
							this.$router.push("/hxmain/hxorder/hxorderone");
						}
					});
					
				}
		},
	}
</script>

<style scoped="scoped">
	.hx-order-seven{
		width: 80%;
		display: flex;
		margin: 0px auto;
		
		margin-top: 3.125rem;
		/* padding-bottom: 5.125rem; */
		padding-bottom: 10px;
		justify-content: space-around;
	}
	
	h3{
		font-family: 'themixitalic';
		font-weight: 400;
		line-height: 1.1;
		color: #0D8EA4;
		margin-bottom: 20px;
		font-size: 24px;
	}
	.left{
		display: flex;
		width: 30rem;
		position: relative;
		justify-content: flex-start;
	}
	.right{
		/* position: relative;
		left: 1.25rem; */
		width:560px;
	}
	
	.btnNextKouSao{
		height: 1.875rem;
		border-radius: 0.625rem;
		background-image: linear-gradient(to right, white, #28D9D5, #2B9BB3);
		border: none;
		margin-bottom: 1.25rem;
	}
	.xy{
		display: none;
	}
	/* 按钮 */
	.nextStep{
		background-color: #0D8EA4 !important;
		border-color: #0D8EA4 !important;
		color: #fff !important;
		min-width: 7.5rem;
	}
	.back{
		min-width: 7.5rem;
		background-color: transparent;
		border-color: #0D8EA4 !important;
		color: #0D8EA4 !important;
	}
</style>
